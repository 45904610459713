import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
   Typography,
   LinearProgress,
   Card,
   CardContent,
   CardActions,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Grid,
   GridList,
   GridListTile
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ChecklistService from '../services/ChecklistService';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TimerIcon from '@material-ui/icons/Timer';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import ViewStreamIcon from '@material-ui/icons/ViewStream';

const cardBackgroundColor = 'primary';
const useStyles = makeStyles(theme => ({
   card: {
      background: `linear-gradient(145deg, ${theme.palette[cardBackgroundColor].light} 0%, ${theme.palette[cardBackgroundColor].dark} 100%);`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: theme.palette[cardBackgroundColor].contrastText,
      marginBottom: theme.spacing(2)
   },
   info: { textTransform: 'none' },
   gridRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'left',
      overflow: 'hidden',
      marginBottom: theme.spacing(3)
      // backgroundColor: theme.palette.background.paper
   },
   gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)'
   }
}));

export default function ChecklistManagement() {
   const [checklists, setChecklists] = useState('loading');
   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
   const [checklistToDelete, setChecklistToDelete] = useState();
   const [viewMode, setViewMode] = useState('horizontal');
   const history = useHistory();

   useEffect(() => {
      ChecklistService.all().then(response => {
         setChecklists(response);
      });
   }, []);

   const classes = useStyles();

   if (checklists === 'loading') return <LinearProgress />;

   const getBackgroundStyle = checklist => {
      if (!checklist.cover) return {};
      return {
         backgroundImage: `linear-gradient(
                        rgba(0, 0, 0, 0.2) 0%, 
                        rgba(0, 0, 0, 0.5) 100%
                        ), url(${checklist.cover})`
      };
   };

   const goTo = url => history.push(url);
   const deleteChecklist = id => {
      ChecklistService.delete(id).then(() => {
         const checklistList = checklists.filter(
            checklist => checklist.id !== id
         );
         setShowDeleteConfirmation(false);
         setChecklists(checklistList);
      });
   };

   let checklistsByCategory = checklists.reduce((acc, checklist) => {
      const category = checklist.category ? checklist.category.title : 'others';
      if (!acc.hasOwnProperty(category)) acc[category] = [];
      acc[category].push(checklist);
      return acc;
   }, {});

   // moving "others" category to the end of the list
   if (checklistsByCategory['others']) {
      const othersLabel = 'Altro';
      const { others, ...checklistsWithCategory } = checklistsByCategory;
      checklistsByCategory = {
         ...checklistsWithCategory,
         [othersLabel]: others
      };
   }

   const ChecklistCard = ({ checklist }) => {
      return (
         <Card className={classes.card} style={getBackgroundStyle(checklist)}>
            <CardContent>
               <Typography
                  variant="h6"
                  component="h2"
                  className={classes.cardTitle}
                  gutterBottom
                  noWrap
               >
                  {checklist.title}
               </Typography>

               <Button
                  variant="text"
                  color="inherit"
                  startIcon={<FormatListBulletedIcon />}
                  className={classes.info}
               >
                  {checklist.tasks.length} passaggi
               </Button>
               {checklist.estimated_time && (
                  <Button
                     variant="text"
                     color="inherit"
                     startIcon={<TimerIcon />}
                     className={classes.info}
                  >
                     {checklist.estimated_time} minuti
                  </Button>
               )}
            </CardContent>
            <CardActions>
               <Button
                  color="inherit"
                  onClick={() => {
                     goTo(`/app/checklist-submit?checklist=${checklist.id}`);
                  }}
               >
                  Registra
               </Button>
               <Button
                  color="inherit"
                  onClick={() => {
                     goTo(`/app/checklist-edit/${checklist.id}`);
                  }}
               >
                  Modifica
               </Button>
               <Button
                  color="inherit"
                  onClick={() => {
                     setShowDeleteConfirmation(true);
                     setChecklistToDelete(checklist);
                  }}
               >
                  Elimina
               </Button>
            </CardActions>
         </Card>
      );
   };

   const ChecklistGrid = ({ checklists }) => {
      const cols = 1.1;
      if (viewMode === 'vertical' || checklists.length === 1) {
         return checklists.map(checklist => (
            <ChecklistCard key={checklist.id} checklist={checklist} />
         ));
      }
      return (
         <div className={classes.gridRoot}>
            <GridList className={classes.gridList} cols={cols}>
               {checklists.map(checklist => {
                  return (
                     <GridListTile key={checklist.id}>
                        <ChecklistCard checklist={checklist} />
                     </GridListTile>
                  );
               })}
            </GridList>
         </div>
      );
   };

   return (
      <>
         <Grid container justify="space-between">
            <Typography variant="h5" gutterBottom>
               Gestisci prove
            </Typography>

            <ToggleButtonGroup
               value={viewMode}
               exclusive
               onChange={(event, newViewMode) => {
                  setViewMode(newViewMode);
               }}
               aria-label="Vista"
            >
               <ToggleButton value="horizontal" aria-label="Orizzontale">
                  <ViewArrayIcon />
               </ToggleButton>
               <ToggleButton value="vertical" aria-label="Verticale">
                  <ViewStreamIcon />
               </ToggleButton>
            </ToggleButtonGroup>
         </Grid>

         {Object.keys(checklistsByCategory).map(category => {
            const checklists = checklistsByCategory[category];
            return (
               <span key={category}>
                  <Typography variant="h6" gutterBottom>
                     {category}
                  </Typography>
                  <ChecklistGrid checklists={checklists} />
               </span>
            );
         })}
         <Dialog
            open={showDeleteConfirmation}
            onClose={setShowDeleteConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               {'Sei sicuro di voler cancellare questa prova?'}
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  Cancellando la prova{' '}
                  <strong>
                     "{checklistToDelete && checklistToDelete.title}"
                  </strong>{' '}
                  eliminerai anche tutti i suoi passaggi e i risultati salvati
                  fino ad ora.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  onClick={() => {
                     setShowDeleteConfirmation(false);
                  }}
                  color="primary"
                  autoFocus
               >
                  Annulla
               </Button>
               <Button
                  onClick={() => {
                     deleteChecklist(checklistToDelete.id);
                  }}
                  color="primary"
               >
                  Conferma
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
}
