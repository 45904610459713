import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";

const appendParamsToUrl = (url, params = {}) => {
  if (Object.keys(params).length) {
    url += "?";
    url += Object.keys(params)
      .map((key) => {
        const value = params[key];
        return `${key}=${value}`;
      })
      .join("&");
  }
  return url;
};

const useStyles = makeStyles((theme) => ({
  wrapper: (props) => ({
    position: "fixed",
    bottom: props.hasBottomNavigation ? theme.spacing(9) : theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  }),
  fab: {
    marginLeft: theme.spacing(2),
  },
}));

function AppFab({ location }) {
  const history = useHistory();

  let data = [];

  const createChecklist = {
    label: "Crea una prova",
    onClick: () => {
      history.push("/app/checklist-creation");
    },
    icon: <AddIcon />,
  };

  const registerChecklist = ({ patient, checklist }) => {
    return {
      label: "Esegui prova",
      onClick: () => {
        let url = "/app/checklist-submit";
        const params = {};
        if (patient) params["patient"] = patient;
        if (checklist) params["checklist"] = checklist;
        url = appendParamsToUrl(url, params);
        history.push(url);
      },
      icon: <AddIcon />,
    };
  };

  const registerChallengingBehavior = ({ patient }) => {
    return {
      label: "Registra C.P.",
      onClick: () => {
        let url = "/app/challenging-behavior-registration";
        const params = {};
        if (patient) params["patient"] = patient;
        url = appendParamsToUrl(url, params);
        history.push(url);
      },
      icon: <AddIcon />,
    };
  };

  const registerGoalResult = ({ patient }) => {
    return {
      label: "Registra Obiettivo",
      onClick: () => {
        let url = `/app/patients/${patient}/goal-results/registration`;
        history.push(url);
      },
      icon: <AddIcon />,
    };
  };

  const registerHandover = (params = {}) => {
    const { patient } = params;
    return {
      label: "Registra Consegna",
      onClick: () => {
        const url = patient
          ? `/app/patients/${patient}/handover-creation`
          : `/app/handover-creation`;
        console.log("registerHandover", url);
        history.push(url);
      },
      icon: <AddIcon />,
    };
  };

  switch (location.pathname) {
    case "/app/checklist-management":
      data.push(createChecklist);
      break;
    case "/app/handovers":
      data.push(registerHandover());
      break;
  }

  let patientDetailsMatch = location.pathname.match(
    /^\/app\/patients\/(\d+)\//
  );

  const checklistExecutionMatch = location.pathname.match(
    /^\/app\/patients\/(\d+)\/checklist-executions$/
  );

  const checklistEditMatch = location.pathname.match(
    /^\/app\/checklist-edit\/(\d+)$/
  );

  const checklistExecutionResult = location.pathname.match(
    /^\/app\/patients\/(\d+)\/checklists\/(\d+)\/executions\/(\d+)$/
  );

  const goalResultsMatch = location.pathname.match(
    /^\/app\/patients\/(\d+)\/goal-results$/
  );

  const challengingBehaviorsMatch = location.pathname.match(
    /^\/app\/patients\/(\d+)\/challenging-behaviors$/
  );

  const handoversMatch = location.pathname.match(
    /^\/app\/patients\/(\d+)\/handovers$/
  );

  if (checklistEditMatch) {
    data.push(createChecklist);
  }

  if (checklistExecutionMatch) {
    data.push(
      registerChecklist({
        patient: checklistExecutionMatch[1],
      })
    );
  }

  if (checklistExecutionResult) {
    // remove bottom navigation space
    patientDetailsMatch = null;
    data.push(
      registerChecklist({
        patient: checklistExecutionResult[1],
        checklist: checklistExecutionResult[2],
      })
    );
  }

  if (challengingBehaviorsMatch) {
    data.push(
      registerChallengingBehavior({
        patient: challengingBehaviorsMatch[1],
      })
    );
  }

  const classes = useStyles({
    hasBottomNavigation: !!patientDetailsMatch,
  });

  if (goalResultsMatch) {
    data.push(
      registerGoalResult({
        patient: goalResultsMatch[1],
      })
    );
  }

  if (handoversMatch) {
    data.push(
      registerHandover({
        patient: handoversMatch[1],
      })
    );
  }

  if (!data.length) return null;
  data = data.map((d) => (
    <Fab
      color={d.color || "secondary"}
      onClick={d.onClick}
      aria-label={d.label}
      key={d.label}
      className={classes.fab}
    >
      {d.icon}
    </Fab>
  ));
  return <div className={classes.wrapper}>{data}</div>;
}

export default withRouter(AppFab);
